export default function findChangedFields(prevState, currentState) {
    const changedFields = {};

    Object.keys(currentState).forEach((key) => {
      if (prevState[key] !== currentState[key]) {
        changedFields[key] = {
          prevValue: prevState[key],
          currentValue: currentState[key]
        };
      }
    });

    return changedFields;
  }


  export function capitalizeFirstLetter(str
    
  )
  {  
    if (typeof str !== 'string' || str.length === 0) return str;
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
