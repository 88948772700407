import React, { useEffect, useState } from "react";
import "./MyReportList.scss";
import ToggleButton from "../../../components/Buttons/ToggleButton/ToggleButton";
import { Select, Dropdown, Menu, Input, Tooltip } from "antd";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  Pagination,
  TableRow,
  Stack,
  TextField,
  InputAdornment
} from "@mui/material";
import { Tags } from "../../../components/Tags/Tags";
import {
  useLazyGetAllReportsQuery,
  useGetReportTypeQuery,
  usePostFilteredReportMutation,
  useDeleteReportMutation,
  usePutloackUnlockReportMutation
} from "../../../state/api";
import WidgetLoader from "../../../components/Loaders/WidgetLoader";
import dayjs from "dayjs";
import { capitalizeFirstLetter } from "../../../helper/Utils/utilitiyFunctions";
import { CloseCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import CustomModal from "../../../components/Modal/Modal";
import { useSnackbar } from "../../../components/snackbar/SnackbarContext";

const tableHeader = [
  "Framework",
  "Report name",
  "Reporting period",
  "Created by",
  "Created on",
  "Due date",
  "Status",
  " ",
  ""
];

const MyReportList = () => {
  const navigate = useNavigate();
  const { openSnackbar } = useSnackbar();
  const [activeButton, setActiveButton] = useState("current");
  const [getAllReports, resultAllReports] = useLazyGetAllReportsQuery();
  const [getFilteredReports, resultFilteredReports] = usePostFilteredReportMutation({ fixedCacheKey: "reportTable" });
  const [deleteReports] = useDeleteReportMutation();
  const [putLockUnlockReport, resultLockUnlockReport] = usePutloackUnlockReportMutation();
  const postPerPage = 10;
  const [currentPage, SetCurrentPage] = useState(0);
  const [totalCount, setTotalCount] = useState(0);
  const [allReports, setallReports] = useState();
  const [activeDots, setActiveDots] = useState();
  const [reportingFramework, setReportingFramework] = useState("");
  const reportTypeData = useGetReportTypeQuery();
  const [allReportsType, setAllReportsType] = useState([]);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [lockUnlockModel, setLockUnlockModelOpen] = useState(false);
  const [rowWiseReportInfo, setRowWiseReportInfo] = useState();
  const [filterApplied, setFilterApplied] = useState(false);
  const [loadingReports, setLoadingReports] = useState(false);

  const [searchTerm, setSearchTerm] = useState("");

  useEffect(() => {
    setAllReportsType(reportTypeData.data?.data);
  }, [reportTypeData.data]);

  useEffect(() => {
    const getReport = async () => {
      setLoadingReports(true);
      try {
        await getAllReports({
          isLocked: activeButton === "current" ? false : true,
          from: `${parseInt(currentPage) - 1 < 0 ? 0 : parseInt(currentPage) - 1}`,
          size: postPerPage
        });
        setLoadingReports(false);
      } catch (error) {
        console.error(error);
        setLoadingReports(false);
      }
    };
    getReport();
  }, [activeButton]);

  useEffect(() => {
    if (resultAllReports?.data?.data) {
      setallReports(resultAllReports?.data);
    }
  }, [resultAllReports?.data?.data]);

  const createReport = () => {
    navigate(`/allreports/create?report=${"brsr"}`, {
      state: { report: "brsr", reportID: "6558869409aad4c710755645" }
    });
  };

  const handleButtonClick = (type) => {
    setActiveButton(type);
  };

  const getPayLoad = (pageNo) => {
    let body = {
      isLocked: activeButton === "current" ? false : true,
      from: `${parseInt(pageNo) - 1 < 0 ? 0 : parseInt(pageNo) - 1}`,
      size: postPerPage
    };
    return body;
  };

  const handlePageChange = async (e, value) => {
    if (value - 1 < 0) {
      SetCurrentPage(0);
    } else {
      SetCurrentPage((value - 1) * postPerPage);
    }
    try {
      if (value - 1 < 0) {
        await getAllReports(getPayLoad(0), true);
      } else {
        await getAllReports(getPayLoad((value - 1) * postPerPage), true);
      }
    } catch (error) {
      console.log(error);
    }
  };

  function getReportTypeById(id) {
    const report = allReportsType.find((report) => report._id === id);
    return report ? report.reportType : null;
  }

  const handleCreateReport = () => {
    navigate(`/allreports/create?report=${getReportTypeById(reportingFramework)}`, {
      state: { report: "brsr", reportID: reportingFramework }
    });
  };

  const filterBody = {
    isLocked: activeButton === "current" ? false : true,
    from: `${parseInt(currentPage) - 1 < 0 ? 0 : parseInt(currentPage) - 1}`,
    size: postPerPage
  };

  const handleReportFilterChange = (val) => {
    if (val === "") {
      getAllReports(
        {
          ...filterBody
        },
        true
      );
      setFilterApplied(false);
    } else {
      getAllReports(
        {
          reportType: val,
          ...filterBody
        },
        true
      );
      setFilterApplied(true);
    }
  };

  const handleReportStatusChange = (val) => {
    if (val === "all") {
      getAllReports(
        {
          ...filterBody
        },
        true
      );
      setFilterApplied(false);
    } else {
      getAllReports(
        {
          status: val,
          ...filterBody
        },
        true
      );
      setFilterApplied(true);
    }
  };

  const handleYearSelected = (val) => {
    if (val === "all") {
      getAllReports(
        {
          ...filterBody
        },
        true
      );
      setFilterApplied(false);
    } else {
      getAllReports(
        {
          year: val,
          ...filterBody
        },
        true
      );
      setFilterApplied(true);
    }
  };

  const onSearch = (search) => {
    if (!search) {
      getAllReports({ ...filterBody });
      setFilterApplied(false);
    } else {
      getAllReports({
        search: search,
        ...filterBody
      });
      setFilterApplied(true);
    }
  };
  const handleSearchChange = (e) => {
    const value = e.target.value;
    setSearchTerm(value);
    onSearch(value);
  };

  const clearSearch = () => {
    setSearchTerm("");
    onSearch("");
  };

  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      onSearch(searchTerm);
    }
  };

  const openDropDown = (item, ind) => {
    setActiveDots(ind);
    setRowWiseReportInfo(item);
  };

  const handleMenuClick = (e) => {
    if (e.key === "1") {
    } else if (e.key === "2") {
      setLockUnlockModelOpen(true);
    } else if (e.key === "3") {
      setDeleteModalOpen(true);
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="1" icon={<img src="/images/BRSR/download.svg" alt="icon" />}>
        Download report
      </Menu.Item>
      {rowWiseReportInfo?.status === "completed" ? (
        <Menu.Item key="2" icon={<img src="/images/BRSR/publish-icon.svg" alt="icon" />}>
          {activeButton === "past" ? "Unpublish report" : "Publish report"}
        </Menu.Item>
      ) : (
        ""
      )}
      <Menu.Item key="3" icon={<img src="/images/BRSR/trash-icon.svg" alt="icon" />}>
        Delete report
      </Menu.Item>
    </Menu>
  );

  const confirmDelete = async () => {
    const res = await deleteReports(rowWiseReportInfo._id);
    if (res.data.success) {
      openSnackbar(res?.data?.message, "success");
      setDeleteModalOpen(false);
    } else {
      openSnackbar(res?.data?.message, "warning");
      setDeleteModalOpen(false);
    }
  };

  const confirmLock = async () => {
    let postBody = { id: rowWiseReportInfo._id, isLocked: activeButton === "past" ? false : true };
    try {
      const res = await putLockUnlockReport(postBody);
      if (res.data.success) {
        openSnackbar(res?.data?.message, "success");
        setLockUnlockModelOpen(false);
      } else {
        openSnackbar(res?.data?.message, "warning");
        setLockUnlockModelOpen(false);
      }
    } catch (error) {
      console.log(error);
      setLockUnlockModelOpen(false);
    }
  };

  return (
    <>
      <CustomModal modalOpen={lockUnlockModel} setModalOpen={setLockUnlockModelOpen} outSideClose={false}>
        <div className="d-flex gap-2">
          <div>
            <img src="/images/BRSR/color-info-icon.svg" alt="" />
          </div>
          <div style={{ maxWidth: "390px" }}>
            <div>
              <p className="modal-title-plan text-start" style={{ fontSize: "16px" }}>
                Are you sure you want to {activeButton === "past" ? "unpublish" : "publish"} this report?
              </p>
              <p className="sub-heading-text" style={{ fontSize: "14px" }}>
                This report will not be editable if proceeds, and all contributors will be notified. It will be moved to
                the ‘Past reports’ tab.
              </p>
            </div>

            <div className="d-flex gap-4 mt-3">
              <button type="button" className="btn btn-outline-success " onClick={() => setLockUnlockModelOpen(false)}>
                Cancel
              </button>
              <button type="button" className="btn btn-success" onClick={confirmLock}>
                {activeButton === "past" ? "Unpublish report" : "Publish report"}
              </button>
            </div>
          </div>
        </div>
      </CustomModal>

      <CustomModal modalOpen={deleteModalOpen} setModalOpen={setDeleteModalOpen} outSideClose={false}>
        <div style={{ padding: "20px" }}>
          <p className="modal-title-plan text-center" style={{ fontSize: "18px" }}>
            Are you sure you want to delete this report permanently?
          </p>
          <div className="d-flex gap-3" style={{ marginTop: "29px" }}>
            <button type="button" className="btn btn-outline-success " onClick={() => setDeleteModalOpen(false)}>
              Cancel
            </button>
            <button type="button" className="btn btn-danger" onClick={confirmDelete}>
              Delete
            </button>
          </div>
        </div>
      </CustomModal>

      <div className="report-list-mainwrapper">
        <div className="top-wrapper d-flex justify-content-between align-items-center">
          <div>
            <h6 className="report-list-title">My Reports</h6>
          </div>
          <div>
            <button type="button" className="btn btn-success btn-sm" onClick={createReport}>
              {"Create report >"}
            </button>
          </div>
        </div>
        <div className="toggle-buttons mt-4">
          <ToggleButton
            text="Current reports"
            isActive={activeButton === "current"}
            onClick={() => handleButtonClick("current")}
          />
          <ToggleButton
            text="Past reports"
            isActive={activeButton === "past"}
            onClick={() => handleButtonClick("past")}
          />
        </div>
        <div className="report-table-wrapper mt-3">
          <div className="d-flex justify-content-between select-box">
            <div className="d-flex gap-3">
              <Select
                placeholder="Report type"
                variant="filled"
                style={{ flex: 1, backgroundColor: "#F9F9F9", width: "150px" }}
                onChange={(val) => handleReportFilterChange(val)}
              >
                <Select.Option value={""}>All Types</Select.Option>
                {allReportsType?.map((item, ind) => (
                  <Select.Option key={ind + "type"} value={item._id}>
                    {item.reportType.toUpperCase()}
                  </Select.Option>
                ))}
              </Select>

              {activeButton === "current" ? (
                <Select
                  placeholder="Report status"
                  variant="filled"
                  style={{ flex: 1, backgroundColor: "#F9F9F9", width: "150px" }}
                  onChange={(val) => handleReportStatusChange(val)}
                >
                  <Select.Option value={"all"}>All status</Select.Option>
                  <Select.Option value={"pending"}>Pending</Select.Option>
                  <Select.Option value={"overdue"}>OverDue</Select.Option>
                  <Select.Option value={"completed"}>Completed</Select.Option>
                </Select>
              ) : (
                ""
              )}
              <Select
                placeholder="Reporting period"
                variant="filled"
                style={{ flex: 1, backgroundColor: "#F9F9F9", width: "200px" }}
                onChange={(newValue) => handleYearSelected(newValue)}
              >
                <Select.Option value={"all"}>All period</Select.Option>
                <Select.Option value={"2024-2025"}>2024-2025</Select.Option>
                <Select.Option value={"2023-2024"}>2023-2024</Select.Option>
                <Select.Option value={"2022-2023"}>2022-2023</Select.Option>
                <Select.Option value={"2021-2022"}>2021-2022</Select.Option>
              </Select>
            </div>
            <div>
              <Input
                size="meduim"
                value={searchTerm}
                placeholder={`Search report name`}
                suffix={
                  searchTerm !== "" ? (
                    <CloseCircleOutlined onClick={clearSearch} style={{ cursor: "pointer", color: "#1890ff" }} />
                  ) : (
                    <img src="images/icons/search-icon.svg" alt="icon" />
                  )
                }
                onChange={handleSearchChange}
                onKeyPress={handleKeyPress}
              />
            </div>
          </div>

          {resultAllReports.isLoading || loadingReports ? (
            <div className="empty-wrapper">
              <WidgetLoader />
            </div>
          ) : allReports?.data.totalCount === 0 ? (
            <div
              className="d-flex flex-column align-items-center justify-content-center text-center w-100 p-"
              style={{ height: "60vh", paddingTop: "50px", paddingBottom: "50px" }}
            >
              <img src="images/BRSR/empty-report.svg" alt="info-icon" className="mb-2" />
              <h6 className="heading-text mb-2">
                No{" "}
                {activeButton === "past"
                  ? "past report found."
                  : filterApplied
                  ? "reports found."
                  : "reports created yet."}
              </h6>
              {!filterApplied && activeButton === "current" && (
                <>
                  <p className="sub-heading-text mb-3">Select Reporting framework and create new report.</p>
                  <div>
                    <Select
                      placeholder="Select a framework"
                      size="large"
                      value={reportingFramework}
                      onChange={(value) => setReportingFramework(value)}
                      className="dropdown-antd"
                      style={{ width: "300px" }}
                    >
                      {allReportsType.map((item, ind) => (
                        <Select.Option key={item._id + ind} value={item._id} disabled={item.reportType !== "brsr"}>
                          {item.header} framework
                        </Select.Option>
                      ))}
                    </Select>

                    <div className="mt-4">
                      <button
                        type="button"
                        className={`btn rounded-2${reportingFramework !== "" ? " btn btn-success" : ""}`}
                        style={{ backgroundColor: "#EBF6FF", color: "#16161E52" }}
                        onClick={handleCreateReport}
                      >
                        {"Create report"}
                      </button>
                    </div>
                  </div>
                </>
              )}
            </div>
          ) : (
            allReports?.data.totalCount > 0 && (
              <>
                <div className="report-table">
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          {tableHeader.map((item, index) => (
                            <TableCell key={index + "item"}>
                              <div className="d-flex gap-1 text-nowrap text-start">{item}</div>
                            </TableCell>
                          ))}
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {allReports?.data?.reportList.map((item, ind) => (
                          <TableRow key={item + "report"}>
                            <TableCell>
                              <Tags
                                bgColor={
                                  item.reportType === "ghg"
                                    ? "#B1F0DF"
                                    : item.reportType === "tcfd"
                                    ? "#F9E3CB"
                                    : "#FEE5FA"
                                }
                                border={"#F9E1EF"}
                                tagName={item.reportType.toUpperCase()}
                              />
                            </TableCell>
                            <TableCell>{item.name}</TableCell>
                            <TableCell>{item.fyear}</TableCell>
                            <TableCell>
                              <p>{item.createdBy.name}</p>
                              <Tooltip
                                placement="topLeft"
                                title={item.createdBy.email}
                                overlayStyle={{
                                  color: "#FFF",
                                  fontSize: "12px",
                                  fontWeight: "400"
                                }}
                              >
                                <p
                                  className="sub-text"
                                  style={{
                                    whiteSpace: "nowrap",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis",
                                    maxWidth: "155px"
                                  }}
                                >
                                  {item.createdBy.email}
                                </p>
                              </Tooltip>
                            </TableCell>
                            <TableCell>
                              {dayjs(item.createdAt).format("YYYY-MM-DD")}
                              <p className="sub-text">{dayjs(item.createdAt).format("HH:mm:ss")}</p>
                            </TableCell>
                            <TableCell>{dayjs(item.createdAt).format("YYYY-MM-DD")}</TableCell>
                            <TableCell
                              style={{
                                color:
                                  item.status === "pending"
                                    ? "#FFA11E"
                                    : item.status === "overdue"
                                    ? "#FF535B"
                                    : activeButton === "past"
                                    ? "#16161EAD"
                                    : "#38CB24"
                              }}
                            >
                              {activeButton === "past" ? (
                                <div className="d-flex align-items-center gap-1">
                                  <img src="/images/BRSR/publish-icon-uparrow.svg" alt="icon" />
                                  {capitalizeFirstLetter(item.status)}
                                </div>
                              ) : (
                                capitalizeFirstLetter(item.status)
                              )}
                            </TableCell>
                            <TableCell style={{ color: "#1F93EF", cursor: "pointer" }}>
                              {item.status === "completed" || activeButton === "past" ? "Preview" : "Complete >"}
                            </TableCell>
                            <TableCell onClick={() => openDropDown(item, ind)}>
                              <Dropdown overlay={menu} placement="bottomRight" trigger={["click"]}>
                                {activeDots === ind ? (
                                  <img src="/images/BRSR/active-three-dots.svg" className="cursor-pointer" alt="icon" />
                                ) : (
                                  <img
                                    src="/images/BRSR/de-active-three-dots.svg"
                                    className="cursor-pointer"
                                    alt="icon"
                                  />
                                )}
                              </Dropdown>
                            </TableCell>
                          </TableRow>
                        ))}
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div className="mt-auto py-4 d-flex justify-content-end">
                  <Stack spacing={2}>
                    <Pagination
                      count={Math.ceil(allReports?.data.totalCount / postPerPage)}
                      shape="rounded"
                      onChange={handlePageChange}
                    />
                  </Stack>
                </div>
              </>
            )
          )}
        </div>
      </div>
    </>
  );
};

export default MyReportList;
