export const brsrReportEndPoint = (build) => ({
  getAllFacility: build.query({
    query: () => ({
      url: `reports/getAllFacilities`
    }),
    providesTags: ["facility"]
  }),

  getAllMember: build.query({
    query: (arg) => ({
      url: `reports/getUsers`,
      params: { ...arg }
    }),

    providesTags: ["user"]
  }),

  postAssignContributor: build.mutation({
    query: (postBody) => ({
      url: `reports/assignContributor`,
      method: "POST",
      body: postBody
    }),

    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: [""]
  }),

  getUserCountStatus: build.query({
    query: (arg) => ({
      url: `reports/usersCountStatusWise`,
      params: { ...arg }
    }),

    providesTags: ["userCountStatus"]
  }),

  postCreateBrsrReport: build.mutation({
    query: (postBody) => ({
      url: `reports`,
      method: "POST",
      body: postBody
    }),
    transformResponse: (response) => {
      return response;
    },

    invalidatesTags: [""]
  }),
 

  getPreviousYearReport: build.query({
    query: (arg) => ({
      url: `reports/getPreviousBRSRReports`,
      params: { ...arg }
    }),

    providesTags: ["previousreport"]
  }),

  getAllReports: build.query({
    query: (arg) => ({
      url: `reports/getreports`,
      params: { ...arg }
    }),

    providesTags: ["Reports"]
  }),

  deleteReport: build.mutation({
    query: (id) => ({
      url: `/reports/${id}`,
      method: "DELETE",
    }),
    invalidatesTags: ["Reports"]
  }),

  putloackUnlockReport: build.mutation({
    query: (postBody) => ({
      url: `reports/lock-unlock-report`,
      method: "PUT",
      body: postBody
    }),
    transformResponse: (response) => {
      return response;
    },
    invalidatesTags: ["Reports"]
  }),

  getContributors: build.query({
    query: (arg) => ({
      url: `reports/getContributors`,
      params: { ...arg }
    }),

    providesTags: ["Contributors"]
  }),

  getContributorsCountRoleWise: build.query({
    query: (arg) => ({
      url: `reports/contributorsCountRoleWise`,
      params: { ...arg }
    }),

    providesTags: ["Contributorscount"]
  }),

  getExitingReports: build.query({
    query: (arg) => ({
      url: `reports/existing-reports`,
      params: { ...arg }
    }),

    providesTags: ["Exitingreports"]
  }),

  putConfirmTeamRole: build.mutation({
    query: (postBody) => ({
      url: `reports/confirmTeam`,
      method: "PUT",
      body: postBody
    }),
    transformResponse: (response) => {
      return response;
    },
    invalidatesTags: ["Role"]
  }),
  putUpdateRole: build.mutation({
    query: (postBody) => ({
      url: `reports/changeRole`,
      method: "PUT",
      body: postBody
    }),
    transformResponse: (response) => {
      return response;
    },
    invalidatesTags: ["ChnageRole"]
  }),


});
