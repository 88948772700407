import React, { useState, useEffect } from "react";
import Chip from "../../../../components/BRSRComponets/Chip/Chip";
import {
  useLazyGetContributorsCountRoleWiseQuery,
  useLazyGetContributorsQuery,
  usePutConfirmTeamRoleMutation
} from "../../../../state/api";
import { TeamMembers } from "../TeamMembers/TeamMembers";
import "./AssignRole.scss";
import WidgetLoader from "../../../../components/Loaders/WidgetLoader";
import PrimaryButton from "../../../../components/Buttons/PrimaryButton/PrimaryButton";
import { useSnackbar } from "../../../../components/snackbar/SnackbarContext";
import { Checkbox, Select } from "antd";
const chips = ["All", "Maker", "Reviewer/approver"];
const AssignRole = ({
  facilityId,
  activeButton,
  activeAssingButton,
  searchValue,
  setRefreshFacility,
  refreshFacility
}) => {
  const { openSnackbar } = useSnackbar();
  const [getContributors, resultContributors] = useLazyGetContributorsQuery();
  const [getContributorsCountRoleWise, resultContributorsCountRoleWise] = useLazyGetContributorsCountRoleWiseQuery();
  const [putConfirmTeamRole, resultConfirmTeamRole] = usePutConfirmTeamRoleMutation();
  const [activeChip, setActiveChip] = useState("All");
  const [contributors, setContributors] = useState([]);
  const [countRoleWise, setCountRoleWise] = useState();
  const [allFacilityWaiseRole, setallFacilityWaiseRole] = useState([]);
  const [singleFacilityWiseRole, setsingleFacilityWiseRole] = useState([]);
  const [selectAll, setSelectAll] = useState(false);
  const [selectRoleForBulk, setSelectRoleForBulk] = useState();


  useEffect(() => {
    if (facilityId) {
      getContributors({ facility: facilityId });
      getContributorsCountRoleWise({ facility: facilityId });
    }
    setActiveChip("All");
  }, [facilityId, activeButton, activeAssingButton ,refreshFacility]);

  useEffect(() => {
    if (resultContributors?.data?.data) {
      setContributors(resultContributors.data.data);
    }
  }, [resultContributors?.data?.data]);

  useEffect(() => {
    if (resultContributorsCountRoleWise?.data?.data) {
      setCountRoleWise(resultContributorsCountRoleWise.data.data);
    }
  }, [resultContributorsCountRoleWise?.data?.data]);

  // console.log("resultContributors", resultContributors);

  const handleChipClick = (chip) => {
    setActiveChip(chip);
    if (chip === "All") {
      setContributors(resultContributors?.data?.data);
    } else {
      setContributors(resultContributors?.data?.data?.filter((item) => item?.role?.includes(chip.toLowerCase())));
    }
  };

  useEffect(() => {
    setallFacilityWaiseRole([]);
    setsingleFacilityWiseRole([]);
    const facilityWise = [];
    const singleFacilityWise = [];

    contributors?.forEach((contributor) => {
      if (contributor.role) {
        if (facilityId === "ALL") {
          facilityWise.push({
            facility: contributor.facilityId,
            contributor: contributor._id,
            role: contributor.role
          });
        } else {
          singleFacilityWise.push({
            contributor: contributor._id,
            role: contributor.role
          });
        }
      }
    });
    if (facilityId === "ALL") {
      // setallFacilityWaiseRole(facilityWise);
    } else {
      setsingleFacilityWiseRole(singleFacilityWise);
    }
  }, [facilityId, resultContributors?.data?.data]);

  useEffect(() => {
    setsingleFacilityWiseRole([]);
    setallFacilityWaiseRole([]);
  }, [activeAssingButton]);

  // console.log("allFacilityWaiseRole", allFacilityWaiseRole);
  // console.log("singleFacilityWiseRole", singleFacilityWiseRole);

  const handleCheck = (contributorId, contributorFacilityId, role) => {
    if (facilityId === "ALL") {
      const existingContributorIndex = allFacilityWaiseRole.findIndex((item) => item.contributor === contributorId);
      if (role !== "remove role") {
        if (existingContributorIndex !== -1) {
          const updatedRoles = allFacilityWaiseRole.map((item) => {
            if (item.contributor === contributorId) {
              return {
                ...item,
                role: role
              };
            }
            return item;
          });
          // Update contributors with new role
          const updatedContributors = contributors.map((item) => {
            if (item._id === contributorId) {
              return {
                ...item,
                role: role
              };
            }
            return item;
          });
          setallFacilityWaiseRole([...updatedRoles]);
          setContributors([...updatedContributors]);
        } else {
          // If contributorId does not exist, add new role entries
          const allSelectedRoleContributors = contributors.filter((item) => item._id === contributorId);

          const updatedRoles = allSelectedRoleContributors.map((item) => {
            return {
              facility: item.facilityId,
              contributor: item._id,
              role: role
            };
          });

          // Update contributors with new role
          const updatedContributors = contributors.map((item) => {
            if (item._id === contributorId) {
              return {
                ...item,
                role: role
              };
            }
            return item;
          });

          setallFacilityWaiseRole([...allFacilityWaiseRole, ...updatedRoles]);
          setContributors([...updatedContributors]);
        }
      } else {
        setallFacilityWaiseRole(allFacilityWaiseRole.filter((item) => item.contributor !== contributorId));
        setContributors(resultContributors.data.data);
      }
    } else {
      // Handle single facility logic
      const existingIndex = singleFacilityWiseRole.findIndex((item) => item.contributor === contributorId);
      if (existingIndex === -1) {
        if (role !== "remove role") {
          setsingleFacilityWiseRole([...singleFacilityWiseRole, { contributor: contributorId, role: role }]);
        }
      } else {
        if (role === "remove role") {
          setsingleFacilityWiseRole(singleFacilityWiseRole.filter((item) => item.contributor !== contributorId));
        } else {
          const updatedRoles = [...singleFacilityWiseRole];
          updatedRoles[existingIndex] = { ...updatedRoles[existingIndex], role: role };
          setsingleFacilityWiseRole(updatedRoles);
        }
      }
    }
  };

  // console.log("allFacilityWaiseRole", allFacilityWaiseRole);

  const handleConfirmContributors = async () => {
    const body = {
      facility: facilityId,
      contributors: facilityId === "ALL" ? allFacilityWaiseRole : singleFacilityWiseRole
    };

    try {
      const response = await putConfirmTeamRole(body);
      if (response?.data?.success) {
        getContributors({ facility: facilityId });
        getContributorsCountRoleWise({ facility: facilityId });
        openSnackbar(response?.data?.message, "success");
        setallFacilityWaiseRole([]);
        setRefreshFacility(!refreshFacility);
        setSelectRoleForBulk();
        setSelectAll(false);
      } else {
        openSnackbar(response?.error?.data?.message, "warning", 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const onCheckAllChange = (e) => {
    const isChecked = e.target.checked;
    setSelectAll(isChecked);
    const contributorsWithEmptyRole = contributors.filter((item) => !item.role);
    const updatedContributors = contributors.map((item) =>
      contributorsWithEmptyRole.some((filteredItem) => filteredItem._id === item._id)
        ? { ...item, isChecked: isChecked }
        : item
    );

    let updatedRoles;
    if (isChecked) {
      updatedRoles = [
        ...allFacilityWaiseRole,
        ...contributorsWithEmptyRole.map((item) => ({
          facility: item.facilityId,
          contributor: item._id,
          role: item.role
        }))
      ];
    } else {
      updatedRoles = allFacilityWaiseRole.filter(
        (role) => !contributorsWithEmptyRole.some((contributor) => contributor._id === role.contributor)
      );
    }
    setallFacilityWaiseRole(updatedRoles);
    setContributors(updatedContributors);
  };

  const handletRoleForBulk = (role) => {
    setSelectRoleForBulk(role);
    const updatedAllFacilityWaiseRole = allFacilityWaiseRole.map((item) => {
      return {
        ...item,
        role: role
      };

      return item;
    });
    setallFacilityWaiseRole(updatedAllFacilityWaiseRole);
  };

  const handleCheckForBulk = (contributorId, contributorFacilityId, role) => {
    setSelectAll(false);
    const isAlreadyChecked = allFacilityWaiseRole.some((item) => item.contributor === contributorId);
    if (isAlreadyChecked) {
      const updatedRoles = allFacilityWaiseRole.filter((item) => item.contributor !== contributorId);
      const updatedContributors = contributors.map((item) =>
        item._id === contributorId ? { ...item, isChecked: false } : item
      );

      setallFacilityWaiseRole(updatedRoles);
      setContributors(updatedContributors);
    } else {
      const contributorsWithEmptyRole = contributors.filter((item) => !item.role);

      console.log("handleCheckForBulk if not checked", contributorsWithEmptyRole);
      const updatedRoles = [
        ...allFacilityWaiseRole,
        ...contributorsWithEmptyRole
          .filter((item) => item._id === contributorId)
          .map((item) => ({
            facility: item.facilityId,
            contributor: item._id,
            role: item.role
          }))
      ];
      const updatedContributors = contributors.map((item) =>
        item._id === contributorId ? { ...item, isChecked: true } : item
      );
      setallFacilityWaiseRole(updatedRoles);
      setContributors(updatedContributors);
    }
  };

  const gotoDashborad = () => {};

  return (
    <div className="assing-role-wrapper">
      <div className="d-flex justify-content-between align-items-center p-4">
        {activeAssingButton === "bulk" && facilityId === "ALL" ? (
          <div class="select-all-checkbox">
            <Checkbox onChange={onCheckAllChange} checked={selectAll}>
              Select all
            </Checkbox>
          </div>
        ) : (
          <p className="sub-heading-text" style={{ fontWeight: "500" }}>
            Name
          </p>
        )}

        <div className="d-flex gap-2 align-items-center">
          {activeAssingButton === "bulk" && (
            <p className="sub-heading-text" style={{ fontWeight: "500" }}>
              Name
            </p>
          )}
          {chips.map((chip) => {
            const safeDataObject = countRoleWise || {};
            const chipKey = chip.toLowerCase();
            const value = safeDataObject[chipKey] !== undefined ? safeDataObject[chipKey] : 0;
            return (
              <Chip
                key={chip}
                label={`${chip}: ${value}`}
                isActive={chip === activeChip}
                onClick={() => handleChipClick(chip)}
              />
            );
          })}
        </div>
      </div>
      {activeAssingButton === "bulk" && facilityId === "ALL" && (
        <div className="px-4 pb-4 d-flex align-items-center gap-2">
          <Select
            size="meduim"
            placeholder="Select role"
            className="dropdown-antd"
            value={selectRoleForBulk}
            onChange={(value) => handletRoleForBulk(value)}
            disabled={allFacilityWaiseRole.length === 0}
            style={{ width: "200px" }}
          >
            <Select.Option value="maker">Maker</Select.Option>
            <Select.Option value="reviwer/approver">Reviwer/approver</Select.Option>
          </Select>

          <p className="sub-heading-text" style={{ fontWeight: "500" }}>
            {allFacilityWaiseRole.length} people selected
          </p>
        </div>
      )}

      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          flexDirection: "column",
          height: "64vh"
        }}
      >
        {resultContributors?.isLoading ? (
          <div className="empty-wrapper">
            <WidgetLoader />
          </div>
        ) : contributors?.length === 0 ? (
          <div className="empty-wrapper">
            <p>No {activeChip === "All" ? "contributors" : activeChip.toLowerCase()} found.</p>
          </div>
        ) : (
          <div className="contributors-wrapper p-4 pt-0">
            {contributors &&
              contributors
                ?.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
                .map((item, inde) => (
                  <TeamMembers
                    key={item + "contributors"}
                    data={item}
                    facilityId={facilityId}
                    handleCheck={handleCheck}
                    activeButton={activeButton}
                    activeAssingButton={activeAssingButton}
                    handleCheckForBulk={handleCheckForBulk}
                    setRefreshFacility={setRefreshFacility}
                    refreshFacility={refreshFacility}
                
                  />

                ))}
          </div>
        )}

        <div style={{ borderTop: "1px solid #E2E2EA" }} className="p-2 mt-2 d-flex gap-4">
          <PrimaryButton
            disabled={singleFacilityWiseRole.length === 0 && allFacilityWaiseRole.length === 0}
            onClick={handleConfirmContributors}
          >
            {"Save assigned role"}
          </PrimaryButton>

          <PrimaryButton onClick={gotoDashborad} disabled={true}>
            {"Continue to dashboard >"}
          </PrimaryButton>
        </div>
      </div>
    </div>
  );
};

export default AssignRole;

//   } else {
// const existingRolesContributors = contributors.filter(
//   (item) => item.facilityId === contributorFacilityId && item.role !== null
// );

// if (existingRolesContributors.length === 0) {
//   const facilityIndex = allFacilityWaiseRole.findIndex((item) => item.contributor === contributorId);
//   if (facilityIndex !== -1) {
//     const updatedRoles = allFacilityWaiseRole.map((item, index) =>
//       index === facilityIndex ? { ...item, role: role } : item
//     );
//     setallFacilityWaiseRole(updatedRoles);
//   } else {
//     setallFacilityWaiseRole([
//       ...allFacilityWaiseRole,
//       {
//         facility: contributorFacilityId,
//         contributor: contributorId,
//         role: role
//       }
//     ]);
//   }
// } else {
//   const exitingUser = existingRolesContributors.map((item) => {
//     return {
//       facility: item.facilityId,
//       contributor: item._id,
//       role: item.role
//     };
//   });
//   const facilityIndex = allFacilityWaiseRole.findIndex((item) => item.contributor === contributorId);

//   if (facilityIndex !== -1) {
//     const updatedRoles = allFacilityWaiseRole.map((item, index) =>
//       index === facilityIndex ? { ...item, role: role } : item
//     );

//     const exitingUserFiltered = exitingUser.filter(
//       (exiting) =>
//         !allFacilityWaiseRole.some(
//           (existing) => existing.contributor === exiting.contributor && existing.facility === exiting.facility
//         )
//     );
//     setallFacilityWaiseRole([...exitingUserFiltered, ...updatedRoles]);
//   } else {
//     setallFacilityWaiseRole([
//       ...allFacilityWaiseRole,
//       ...exitingUser,
//       {
//         facility: contributorFacilityId,
//         contributor: contributorId,
//         role: role
//       }
//     ]);
//   }
// }
