import React, { useEffect, useState } from "react";
import PrimaryButton from "../../../components/Buttons/PrimaryButton/PrimaryButton";
import { Button, Divider, Tooltip } from "antd";
import "./AddContributors.scss";
import { useSelector } from "react-redux";
import {
  useLazyGetAllFacilityQuery,
  useLazyGetAllMemberQuery,
  useLazyGetUserCountStatusQuery,
  useLazyGetUserDetailByIdQuery,
  usePostAssignContributorMutation
} from "../../../state/api";
import { useLocation, useNavigate } from "react-router-dom";
import { TeamMembers } from "./TeamMembers/TeamMembers";
import { Chip } from "@mui/material";
import { useSnackbar } from "../../../components/snackbar/SnackbarContext";
import AssignRole from "./AssignRole/AssignRole";
import ToggleButton from "../../../components/Buttons/ToggleButton/ToggleButton";

const AddContributors = () => {
  const { openSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const userId = useSelector((state) => state.global.user);
  const [user, setUser] = useState(null);
  const [getUser, userResult] = useLazyGetUserDetailByIdQuery();
  const [activeIndex, setActiveIndex] = useState(-1);
  const [activeButton, setActiveButton] = useState("confirm");

  const [getAllFaciliyt, resultAllFacility] = useLazyGetAllFacilityQuery();
  const [getAllMember, resultAllMember] = useLazyGetAllMemberQuery();
  const [getUserCountStatus, resultUserCountStatus] = useLazyGetUserCountStatusQuery();
  const [allteamMember, setAllTeamMember] = useState([]);

  const [postpostAssignContributor] = usePostAssignContributorMutation({});
  const [facilityId, setFacilityId] = useState("ALL");
  const [activeChip, setActiveChip] = useState(0);

  const [allFacilityWaise, setallFacilityWaise] = useState([]);
  const [singleFacilityWise, setSingleFacilityWise] = useState([]);
  const [activeAssingButton, setActiveAssingButton] = useState("single");
  const [refreshFacility, setRefreshFacility] = useState(false);

  const [searchValue, setsearchValue] = useState("");
  const location = useLocation();

  const reportName = location.state.reportName;

  useEffect(() => {
    getUser({ id: userId._id }, true);
    getAllFaciliyt({}, true);
  }, []);

  useEffect(() => {
    getAllFaciliyt({});
  }, [refreshFacility]);

  useEffect(() => {
    if (userResult.data?.data) {
      setUser(userResult.data?.data);
    }
  }, [userResult.data?.data]);

  useEffect(() => {
    if (activeButton === "confirm") {
      getAllMember({ facility: facilityId });
      getUserCountStatus({ facility: facilityId });
      setActiveChip(0);
    }
  }, [facilityId, activeIndex]);

  useEffect(() => {
    if (resultAllMember?.data?.data) {
      setAllTeamMember(resultAllMember.data.data);
    }
  }, [resultAllMember?.data?.data]);

  useEffect(() => {
    const filterData = () => {
      if (!resultAllMember?.data?.data) return;
      let filteredData = [...resultAllMember.data.data];
      if (activeChip === 1) {
        filteredData = filteredData.filter((member) => member.isTeamMember);
      } else if (activeChip === 2) {
        filteredData = filteredData.filter((member) => !member.isTeamMember);
      }
      setAllTeamMember(filteredData);
    };

    filterData();
  }, [activeChip]);

  const handleClick = (index, id) => {
    setActiveIndex(index);
    setFacilityId(id);
  };

  const handleButtonClick = (button) => {
    setActiveButton(button);
  };

  const moveToInviteUser = () => {
    navigate("/user/userlist");
  };

  useEffect(() => {
    setallFacilityWaise([]);
    setSingleFacilityWise([]);
    const facilityWise = [];
    const singleFacilityWise = [];
    allteamMember.forEach((member) => {
      if (member.isTeamMember) {
        if (facilityId === "ALL") {
          facilityWise.push({
            facility: member.facilityId,
            contributor: member._id
          });
        } else if (facilityId !== "ALL") {
          singleFacilityWise.push(member._id);
        }
      }
    });
    if (facilityId === "ALL") {
      setallFacilityWaise(facilityWise);
    } else {
      setSingleFacilityWise(singleFacilityWise);
    }
  }, [facilityId, allteamMember]);

  const handleCheck = (contributorId, contributorFacilityId) => {
    if (facilityId === "ALL") {
      const existingIndex = allFacilityWaise.findIndex((item) => item.facility === contributorFacilityId);

      if (existingIndex === -1) {
        setallFacilityWaise([...allFacilityWaise, { facility: contributorFacilityId, contributor: contributorId }]);
      } else {
        setallFacilityWaise(allFacilityWaise.filter((_, index) => index !== existingIndex));
      }
    } else {
      const existingContributorIndex = singleFacilityWise.indexOf(contributorId);

      if (existingContributorIndex === -1) {
        setSingleFacilityWise([...singleFacilityWise, contributorId]);
      } else {
        setSingleFacilityWise(singleFacilityWise.filter((_, index) => index !== existingContributorIndex));
      }
    }
    setAllTeamMember((prevTeamMembers) =>
      prevTeamMembers.map((member) => {
        if (
          contributorFacilityId &&
          contributorFacilityId !== "" &&
          member.facilityId === contributorFacilityId &&
          member._id === contributorId
        ) {
          return { ...member, isTeamMember: !member.isTeamMember };
        } else if (member._id === contributorId && contributorFacilityId === "") {
          return { ...member, isTeamMember: !member.isTeamMember };
        }
        return member;
      })
    );
  };

  const handleConfirm = async () => {
    const body = {
      facility: facilityId,
      contributors: facilityId === "ALL" ? allFacilityWaise : singleFacilityWise
    };

    try {
      const response = await postpostAssignContributor(body);
      if (response?.data?.success) {
        getAllMember({ facility: facilityId });
        getUserCountStatus({ facility: facilityId });
        getAllFaciliyt({});
        setActiveIndex(resultAllFacility?.data?.data?.facilities.length === activeIndex + 1 ? -1 : activeIndex + 1);
        openSnackbar(response?.data?.message, "success");
      } else {
        openSnackbar(response?.error?.data?.message, "warning", 5000);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleToggleButton = (type) => {
    setActiveAssingButton(type);
  };

  return (
    <div className="add-contributors-wrapper">
      <div className="d-flex justify-content-between align-items-center">
        <div>
          <p className="report-name">{reportName}</p>
          <p className="page-heading ">Add Contributors</p>
        </div>
        <div className="d-flex align-items-center gap-2" style={{ maxHeight: "28px" }}>
          <PrimaryButton>Save progress</PrimaryButton>
          <Button size="meduim" style={{ backgroundColor: "#F9F9F9" }}>
            <img src="/images/icons/buttonCross-icon.svg" alt="" /> Cancel
          </Button>
        </div>
      </div>

      <div className="button-container mt-4">
        <button
          className={`button ${activeButton === "confirm" ? "active" : ""}`}
          onClick={() => handleButtonClick("confirm")}
        >
          Confirm team
        </button>
        <Tooltip
          placement="topLeft"
          title={
            resultUserCountStatus?.data?.data?.confirmed > 0
              ? ""
              : "The team needs to be confirmed before assigning the role."
          }
          overlayStyle={{
            color: "#FFF",
            fontSize: "12px",
            fontWeight: "400"
          }}
        >
          <button
            className={`button ${activeButton === "assign" ? "active" : ""}`}
            onClick={() => (resultUserCountStatus?.data?.data?.confirmed > 0 ? handleButtonClick("assign") : "")}
          >
            Assign role
          </button>
        </Tooltip>
      </div>

      <div div className="d-flex content-wrapper">
        <div className="all-facility-container">
          <div className="facility-list-wrapper" style={{ paddingBottom: "10px" }}>
            <p className="heading-text">Select Facility</p>
            <p className="sub-heading-text">Select facility to assign the questions</p>
          </div>

          <div style={{ overflow: "auto" }} className="facility-list-wrapper">
            <div
              className={`d-flex gap-2 cursor-pointer facility-tab  ${activeIndex === -1 ? "active-facility" : ""}`}
              onClick={() => handleClick(-1, "ALL")}
            >
              <div className="bg-white " style={{ width: "22px", height: "22px", textAlign: "center" }}>
                <img
                  src="/images/BRSR/rightArrow-icon.svg"
                  alt="icon"
                  className={`facility-name ${activeIndex === -1 ? "active-name" : ""}`}
                />
              </div>
              <div>
                <p className={`facility-name ${activeIndex === -1 ? "active-name" : ""}`}>{"All facilities"}</p>
                <p className={`sub-heading-text text-nowrap ${activeIndex === -1 ? "active-sub-heading" : ""}`}>
                  {activeButton === "assign" ? "Assigned people : " : " Confirmed team members: "}
                  {resultAllFacility?.data?.data?.totalMembersCount}
                </p>
              </div>
            </div>
            {activeIndex !== -1 &&
              (-1 + 1 >= resultAllFacility?.data?.data?.facilities.length || activeIndex !== -1 + 1) && (
                <Divider className="my-0" />
              )}

            {resultAllFacility?.data?.data?.facilities?.map((item, ind) => (
              <>
                <div
                  key={item + "fac"}
                  className={`d-flex justify-content-between cursor-pointer facility-tab ${
                    activeIndex === ind ? "active-facility" : ""
                  }`}
                  onClick={() => handleClick(ind, item._id)}
                  style={{
                    backgroundColor: !item.isConfigured && activeButton === "assign" ? "rgba(255, 83, 91, 0.10)" : ""
                  }}
                >
                  <div className="d-flex gap-2 ">
                    <div className="bg-white " style={{ width: "22px", height: "22px", textAlign: "center" }}>
                      <p className={`facility-name ${activeIndex === ind ? "active-name" : ""}`}>{ind + 1}</p>
                    </div>
                    <div>
                      <p className={`facility-name ${activeIndex === ind ? "active-name" : ""}`}>{item.name}</p>
                      <p className={`sub-heading-text text-nowrap ${activeIndex === ind ? "active-sub-heading" : ""}`}>
                        {activeButton === "assign" ? "Assigned people : " : " Confirmed team members: "}
                        {item.membersCount}
                      </p>
                    </div>
                  </div>
                  {!item.isConfigured && activeButton === "assign" && (
                    <Tooltip
                      placement="topLeft"
                      title={item.message}
                      overlayStyle={{
                        color: "#FFF",
                        fontSize: "12px",
                        fontWeight: "400"
                      }}
                    >
                      <img src="/images/BRSR/info-red.svg" width={24} />
                    </Tooltip>
                  )}
                </div>
                {activeIndex !== ind &&
                  (ind + 1 >= resultAllFacility?.data?.data?.facilities.length || activeIndex !== ind + 1) && (
                    <Divider className="my-0" />
                  )}
              </>
            ))}
          </div>
        </div>

        {/* Right part from here----------------------------------------------------------- */}
        <div className="confirm-team-wrapper  w-100">
          <div className="p-4 pb-0">
            <p className="heading-text">{activeButton === "assign" ? "Assign role" : "Confirm team"}</p>
            <div className="d-flex justify-content-between mt-1  flex-wrap gap-3">
              <div className="d-flex gap-2 align-items-start">
                <img src="images/BRSR/info-icon.svg" alt="info-icon" width={18} />
                <p className="sub-heading-text">
                  {activeButton === "assign" ? (
                    <>
                      <li>At least 1 maker, reviewer/approver has to be mapped for any one facility.</li>
                      <li>Contributors assigned to multiple facilities must have the same role.</li>
                    </>
                  ) : (
                    "Select team members to assign the role."
                  )}
                </p>
              </div>
              {activeButton === "assign" && facilityId === "ALL" && (
                <div className="toggle-buttons ">
                  <ToggleButton
                    text="Single assignment"
                    isActive={activeAssingButton === "single"}
                    onClick={() => handleToggleButton("single")}
                  />
                  <ToggleButton
                    text="Bulk assignment"
                    isActive={activeAssingButton === "bulk"}
                    onClick={() => handleToggleButton("bulk")}
                  />
                </div>
              )}
            </div>
          </div>

          <div className="p-4 pb-0">
            <div className="invite-container">
              <input
                type="text"
                className="email-input"
                placeholder="Search by name"
                onChange={(e) => setsearchValue(e.target.value)}
              />
              <img src="images/icons/search-icon.svg" alt="icon" />
            </div>
          </div>

          {activeButton === "confirm" ? (
            <>
              <div className="p-4 pt-0">
                <div className="filter-chips-wrapper flex-wrap gap-3 d-flex justify-content-between align-items-center mt-4">
                  <div className="d-flex justify-content-between align-items-center gap-2">
                    <p className="sub-heading-text" style={{ fontWeight: "500" }}>
                      Name
                    </p>
                    <img src="" alt="" />
                    <p className="sub-heading-text" style={{ fontWeight: "500" }}>
                      {singleFacilityWise.length || allFacilityWaise.length} people selected
                    </p>
                  </div>
                  <div className="filter-chips d-flex gap-2">
                    <Chip
                      label={`All:${resultUserCountStatus?.data?.data?.all}`}
                      size="small"
                      sx={{ fontSize: "12px", fontWeight: 400 }}
                      variant={activeChip === 0 ? "" : "outlined"}
                      color={activeChip === 0 ? "primary" : "default"}
                      onClick={() => setActiveChip(0)}
                    />
                    <Chip
                      label={`Confirmed:${resultUserCountStatus?.data?.data?.confirmed}`}
                      size="small"
                      variant={activeChip === 1 ? "" : "outlined"}
                      color={activeChip === 1 ? "primary" : "default"}
                      onClick={() => setActiveChip(1)}
                    />
                    <Chip
                      label={`Unconfirmed:${resultUserCountStatus?.data?.data?.unConfirmed}`}
                      size="small"
                      variant={activeChip === 2 ? "" : "outlined"}
                      color={activeChip === 2 ? "primary" : "default"}
                      onClick={() => setActiveChip(2)}
                    />
                  </div>
                </div>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  flexDirection: "column",
                  height: "64vh"
                }}
              >
                {resultAllMember?.data?.data?.length === 0 ? (
                  <div
                    className="d-flex flex-column align-items-center justify-content-center text-center w-100 p-4"
                    style={{ marginTop: "54px" }}
                  >
                    <img src="/images/BRSR/empty-user-icon.svg" alt="info-icon" className="mb-2" />
                    <p className="heading-text mb-2">No contributor found!</p>
                    <p className="sub-heading-text mb-3">
                      Add contributors from the “Organization” tab of the main menu
                    </p>
                    <div>
                      <button
                        type="button"
                        className="btn btn-success rounded-2"
                        style={{ backgroundColor: "ActiveBorder" }}
                        onClick={moveToInviteUser}
                      >
                        {"Proceed to add contributors >"}
                      </button>
                    </div>
                  </div>
                ) : allteamMember.length === 0 ? (
                  <div
                    className="d-flex flex-column align-items-center justify-content-center text-center w-100 p-4"
                    style={{ marginTop: "54px" }}
                  >
                    <img src="/images/BRSR/empty-user-icon.svg" alt="info-icon" className="mb-2" />
                    <p className="heading-text mb-2">No confirm team found!</p>
                    {/* <p className="sub-heading-text mb-3">
                      Add contributors from the “Organization” tab of the main menu
                    </p>
                    <div>
                      <button
                        type="button"
                        className="btn btn-success rounded-2"
                        style={{ backgroundColor: "ActiveBorder" }}
                        onClick={moveToInviteUser}
                      >
                        {"Proceed to add contributors >"}
                      </button>
                    </div> */}
                  </div>
                ) : (
                  allteamMember && (
                    <div className="team-container p-4 pt-0">
                      {allteamMember
                        ?.filter((item) => item.name.toLowerCase().includes(searchValue.toLowerCase()))
                        .map((item, inde) => (
                          <TeamMembers
                            key={item + inde}
                            data={item}
                            facilityId={facilityId}
                            handleCheck={handleCheck}
                          />
                        ))}
                    </div>
                  )
                )}

                <div style={{ borderTop: "1px solid #E2E2EA" }} className="p-2 mt-2">
                  <PrimaryButton
                    disabled={singleFacilityWise.length === 0 && allFacilityWaise.length === 0}
                    onClick={handleConfirm}
                  >
                    {"Confirm >"}
                  </PrimaryButton>
                </div>
              </div>
            </>
          ) : (
            <AssignRole
              facilityId={facilityId}
              activeButton={activeButton}
              activeAssingButton={activeAssingButton}
              searchValue={searchValue}
              setRefreshFacility={setRefreshFacility}
              refreshFacility={refreshFacility}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default AddContributors;
