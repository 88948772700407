// import React from 'react';
// import PropTypes from 'prop-types';
// import './ToggleButton.scss';

// const ToggleButton = ({ text, onClick, isActive, tooltipTitle, showTooltip }) => {
//   return (
//     <div className="button-wrapper">
//       <button
//         className={`button ${isActive ? "active" : ""}`}
//         onClick={onClick}
//       >
//         {text}
//       </button>
//       {showTooltip && tooltipTitle && (
//         <div className="tooltip">
//           <div className="tooltip-text">{tooltipTitle}</div>
//         </div>
//       )}
//     </div>
//   );
// };

// ToggleButton.propTypes = {
//   text: PropTypes.string.isRequired,
//   onClick: PropTypes.func.isRequired,
//   isActive: PropTypes.bool,
//   tooltipTitle: PropTypes.string,
//   showTooltip: PropTypes.bool
// };

// ToggleButton.defaultProps = {
//   isActive: false,
//   tooltipTitle: '',
//   showTooltip: false
// };

// export default ToggleButton;
// Button.js
import React from "react";
import PropTypes from "prop-types";
import { Tooltip } from "antd";
import "./ToggleButton.scss";

const ToggleButton = ({ text, onClick, isActive, tooltipTitle, showTooltip }) => {
  return (
    <div className="button-wrapper">
      <Tooltip
        placement="topLeft"
        title={tooltipTitle}
        overlayStyle={{
          color: "#FFF",
          fontSize: "12px",
          fontWeight: "400"
        }}
        visible={showTooltip}
      >
        <button className={`button ${isActive ? "active" : ""}`} onClick={onClick}>
          {text}
        </button>
      </Tooltip>
    </div>
  );
};

ToggleButton.propTypes = {
  text: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  tooltipTitle: PropTypes.string,
  showTooltip: PropTypes.bool
};

ToggleButton.defaultProps = {
  isActive: false,
  tooltipTitle: "",
  showTooltip: false
};

export default ToggleButton;
